<template>
      <div class="ddnsRecord">
          <a-textarea class="markdown" v-model="Remark" placeholder="请输入记录"/>
          <a-button class="markdown"  type="primary" @click="_add()">添加</a-button>
          <a-table
                  :data-source="tableList"
                  :pagination="false"
                  :columns="tableColumns"
   
          >

          </a-table>
          <a-pagination
                  v-if="total"
                  style="margin-top: 20px;text-align: right;"

                  @change="ChangePage"
                  :pageSize="Limit"
                  :total="total"

          />

          <div
                  :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
                  style=" z-index: 100000"
          >
              <a-button style="marginRight: 8px" @click="onClose">
                  关闭
              </a-button>
              <!--<a-button type="primary" @click="_action()">-->
                  <!--确定-->
              <!--</a-button>-->
          </div>
      </div>
</template>

<script>
    export default {
        name: "ddnsRecord",
        data(){
            return{
                Remark:'',
                tableList:[],
                tableColumns:[
                    {

                        title:'内容',
                        dataIndex: 'Remark',
                        key: 'Remark',
                        align: 'center',
                    },
                    {

                        title:'创建人',
                        key: 'CreateUser',
                        dataIndex: 'CreateUser',
                        align: 'center',
                    },
                    {

                        title:'创建时间',
                        key: 'CreateTime',
                        dataIndex: 'CreateTime',
                        align: 'center',
                    },
                ],
                total:'',
                Page:'',
                Limit:10,


            }
        },
        props:{
            Id:{
                type:String,
            }
        },
        created(){
            this.loadData();
        },
        methods:{
            loadData(){
                let self = this;
                this.$message.loading('加载中....',0);
                this.$axios.get(12921,{
                    Page:self.Page,
                    Limit:self.Limit,
                    Pid:self.Id,
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                         self.tableList = res.data.data;
                         self.total = res.data.count;
                    }else{
                        self.tableList = [];
                        self.total = '';
                    }
                })
            },
            _add(){
                let self = this;
                if(!this.Remark){
                    this.$message.error('请先输入记录');
                    return false;
                }
                this.$message.loading('操作中...',0);
                this.$axios.get(12922,{
                    Pid:self.Id,
                    Remark:self.Remark
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        self.Remark = '';
                        self.loadData();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            ChangePage(val){
                this.Page = val;
                this.loadData();
            } ,
            onClose(){
                this.Remark ='';
                this.$emit('visible', false)
            }
        }
    }
</script>

<style scoped lang="less">
     .markdown{
         margin-bottom: 20px;
     }

</style>