<template>
    <div class="content">
        <div class="search">
            <div class="tag-group">
                <div class="tag-item" v-if="searchList.length>0" v-for="(item,index) in searchList">
                    <a-tag class="tags">{{item.name}}:</a-tag>
                    <a-tag v-if="item.type=='person'"
                           :closable="closable"

                           v-for="(person,index2) in item.value"
                           @close="() => personClose(index,index2)"
                           :key="person.title"
                           class="tags-active tags">
                        {{person.title}}
                    </a-tag>
                    <a-tag v-if="item.type !='person'" :key="item.field"
                            :closable="closable"
                            @close="() => searchClose(index)"
                            class="tags-active tags"
                    >
                        <span v-if="item.field=='IsAp'">{{item.value==1?'是':'否'}}</span>
                        <span v-else-if="item.field=='Allowed'">{{item.value==1?'允许':'禁止'}}</span>

                        <span v-else>{{item.value}}</span>

                    </a-tag>
                </div>
            </div>
            <a-button type="primary"  @click="_add()">添加</a-button>
        </div>
        <div :style="'width:' +($store.state.app.showNav ? pageWidth - 220 : pageWidth) +'px;'">
            <a-table
                    :data-source="tableList"
                    :pagination="false"
                    :columns="tableColumns"
                    :scroll="{ x: 1500 }"
                    :rowKey="record => record.Id"
            >
                <div slot="Title">
                    <span>标题 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('content',$event,'标题','Title')"></i></span>
                </div>
                <div slot="IsAp">
                    <span>ap自动 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('select',$event,'ap自动','IsAp')"></i></span>
                </div>
                <template slot-scope="text, record" slot="IsAp">
                    <span>{{record.IsAp==1?'是':'否'}}</span>
                </template>

                <div slot="Allowed">
                    <span>状态 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('select',$event,'状态','Allowed')"></i></span>
                </div>
                <template slot-scope="text, record" slot="Allowed">
                    <span>{{record.Allowed==1?'允许':'禁止'}}</span>
                </template>
                <div slot="SchoolName">
                    <span>中心名称 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('person',$event,'中心名称','SchoolName')"></i></span>
                </div>
                <div slot="Operators">
                    <span>运营商 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('select',$event,'运营商','Operators')"></i></span>
                </div>
                <div slot="FreePlan">
                    <span>降费计划 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('select',$event,'降费计划','FreePlan')"></i></span>
                </div>
                <div slot="RouterModel">
                    <span>路由器型号 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('content',$event,'路由器型号','RouterModel')"></i></span>
                </div>
                <template slot-scope="text, record" slot="Remark">
                    <a-tooltip v-if="record.Remark">
                        <template slot="title" >
                            {{record.Remark}}
                        </template>
                        <span v-if="record.Remark">{{record.Remark.substr(0,5)}}</span>

                    </a-tooltip>

                </template>
                <template slot-scope="text, record" slot="action">
                    <span class="table-action" @click="_info(record)">编辑</span>

                    <a-popconfirm title="您确定要更改状态嘛?" @confirm="_Allowed(record.Allowed,record.Id)" okText="确定" cancelText="取消">
                        <span class="table-action" >状态</span>
                    </a-popconfirm>
                    <a-popconfirm title="您确定要删除嘛?" @confirm="_del(record.Id)" okText="确定" cancelText="取消">
                        <span class="table-action" >删除</span>
                    </a-popconfirm>

                    <span class="table-action" @click="_record(record)">维护记录</span>


                </template>




            </a-table>
            <a-pagination
                    v-if="total"
                    style="margin-top: 35px;text-align: right;"
                    v-model="Page"
                    @change="ChangePage"
                    :pageSize="Limit"
                    :total="total"
                    :page-size-options="pageSizeOptions"
                    show-less-items
                    show-size-changer
                    @showSizeChange="onShowSizeChange"
                    :page-size.sync="pageSize"
            />
        </div>

        <tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>
        <!--简历信息-->
        <a-drawer
                :title="Id ? '编辑' : '添加'"
                width="800"
                :closable="false"
                :visible="visible"
                @close="onClose"

        >
            <div style="margin-bottom: 60px">
                <ddnsAction v-if="visible" :infoData="infoData" :Id="Id" @visible="onClose"></ddnsAction>
            </div>
        </a-drawer>
        <!--添加记录-->
        <a-drawer
                :title="infoData.Title+'记录'"
                width="800"
                :closable="false"
                :visible="recordVisible"
                @close="recordClose"

        >
            <div style="margin-bottom: 60px">
                <ddnsRecord v-if="recordVisible"  :Id="Id" @visible="recordClose"></ddnsRecord>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import tableHeader from '../../components/tableHeader/filterBox';
    import {mapState} from 'vuex';
    import ddnsAction from 'pages/System/ddnsAction';
    import ddnsRecord from 'pages/System/ddnsRecord';

    export default {
        name: "ddnsList",
        components: {tableHeader,ddnsAction,ddnsRecord},
        data() {
            return {
                Page: 1,
                Limit: 5,
                tableColumns: [
                    {
                        dataIndex: 'Title',
                        key: 'Title',
                        width: 120,
                        fixed: 'left',
                        align: 'center',
                        slots: {title: 'Title'},
                    },
                    {
                        dataIndex: 'SchoolName',
                        key: 'SchoolName',
                        width: 120,
                        align: 'center',
                        slots: {title: 'SchoolName'},

                    },
                    {
                        dataIndex: 'FreePlan',
                        key: 'FreePlan',
                        width: 120,
                        align: 'center',
                        slots: {title: 'FreePlan'},
                    },
                    {
                        title:'运维说明',
                        dataIndex: 'Remark',
                        key: 'Remark',
                        width: 120,
                        align: 'center',
                        scopedSlots: {customRender: 'Remark'},
                    },
                    {
                        title: "nas用户名",
                        dataIndex: 'NasName',
                        key: 'NasName',
                        width: 100,
                        align: 'center',
                    },
                    {
                        title: "nas密码",
                        dataIndex: 'NasPassword',
                        key: 'NasPassword',
                        width: 100,
                        align: 'center',
                    },

                    {
                        title: "DDNS",
                        dataIndex: 'Ddns',
                        key: 'Ddns',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: "路由器用户名",
                        dataIndex: 'RouterName',
                        key: 'RouterName',
                        width: 100,
                        align: 'center',
                    },
                    {
                        title: "路由器密码",
                        dataIndex: 'RouterPassword',
                        key: 'RouterPassword',
                        width: 140,
                        align: 'center',
                    },
                    {
                        dataIndex: 'RouterModel',
                        key: 'RouterModel',
                        width: 140,
                        align: 'center',
                        slots: {title: 'RouterModel'},
                    },
                    {
                        dataIndex: 'IsAp',
                        key: 'IsAp',
                        width: 120,
                        align: 'center',
                        scopedSlots: {customRender: 'IsAp'},
                        slots: {title: 'IsAp'},
                    },
                    {
                        title: "公网IP",
                        dataIndex: 'Ip',
                        key: 'Ip',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: "内网IP",
                        dataIndex: 'Network',
                        key: 'Network',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: "域控域名",
                        dataIndex: 'Domain',
                        key: 'Domain',
                        width: 120,
                        align: 'center',
                    },
                    {
                        dataIndex: 'Allowed',
                        key: 'Allowed',
                        width: 120,
                        align: 'center',
                        slots: {title: 'Allowed'},
                        scopedSlots: {customRender: 'Allowed'},
                    },

                    {
                        title:'办公网速',
                        dataIndex: 'NetworkSpeed',
                        key: 'NetworkSpeed',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title:'公共网速',
                        dataIndex: 'PublicSpeed',
                        key: 'PublicSpeed',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title:'本月网费',
                        dataIndex: 'Free',
                        key: 'Free',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title:'原本网费',
                        dataIndex: 'OldFree',
                        key: 'OldFree',
                        width: 120,
                        align: 'OldFree',
                    },
                    {
                        title:'计划降费时间',
                        dataIndex: 'FreePlanTime',
                        key: 'FreePlanTime',
                        width: 140,
                        align: 'FreePlanTime',
                    },
                    {
                        title:'完成降费时间',
                        dataIndex: 'FreeCompleteTime',
                        key: 'FreeCompleteTime',
                        width: 140,
                        align: 'FreeCompleteTime',
                    },
                    {
                        dataIndex: 'Operators',
                        key: 'Operators',
                        width: 120,
                        align: 'center',
                        slots: {title: 'Operators'},
                    },


                    {
                        title:'操作',
                        dataIndex: 'action',
                        key: 'action',
                        width: 200,
                        align: 'center',
                        fixed: 'right',
                        scopedSlots: {customRender: 'action'},
                    },


                ],
                tableList: [],

                total: '',
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                pageSize:5,
                selectType: '',
                pageWidth:'',
                pageSizeOptions:['5','10', '20', '30', '40', '50'],
                Id:'',
                visible:false,
                infoData:{},
                searchList:[],
                searchArr:[],
                closable:true,
                IsApArr:[{"name":'是',"value":"1"},{"name":"否","value":"2"}],
                AllowedArr:[{"name":'允许',"value":"1"},{"name":"禁止","value":"2"}],
                OperatorsArr:[{"name":'电信',"value":"电信"},{"name":"移动","value":"移动"},{"name":"联通","value":"联通"},{"name":"鹏博士","value":"鹏博士"}],
                FreePlanArr:[{"name":'计划降费',"value":"计划降费"},{"name":"保持不变","value":"保持不变"},{"name":"洽谈中","value":"洽谈中"},{"name":"完成降费","value":"完成降费"}],
                recordVisible:false,
                
            }
        },
        computed: {
            ...mapState({
                showNav: state => state.app.showNav
            })
        },
        created() {
            this.pageWidth = document.documentElement.clientWidth - 220;
            this.loadData();
        },
        methods: {
            loadData() {
                let self = this;
                let data = {
                    Page: self.Page,
                    Limit: self.Limit,
                }
                if(this.searchList.length>0){
                    for(let i=0;i<self.searchList.length;i++){
                        if (self.searchList[i].type == 'person') {
                            let person = [];
                            if (self.searchList[i].value) {
                                for (let s = 0; s < self.searchList[i].value.length; s++) {
                                    person.push(self.searchList[i].value[s].id);
                                }
                            }
                            data[self.searchList[i].field] = person.toString();
                        } else {
                            data[self.searchList[i].field] = self.searchList[i].value;
                        }
                    }
                }
                this.$message.loading('加载中...', 0);
                this.$axios.get(12917, data, res => {
                    this.$message.destroy();
                    if (res.data.code == 1) {
                        self.tableList = res.data.data;
                        self.total = res.data.count;
                    } else {
                        self.tableList = [];
                        self.total = '';
                        this.$message.error(res.data.msg);
                    }
                })
            },
            personClose(key, i) {
                let valueArr = this.searchList[key].value;
                if (valueArr.length == 1) {
                    this.searchList.splice(key, 1);
                    this.searchArr.splice(key,1);
                } else {
                    valueArr.splice(i, 1);
                    this.searchList[key].value = valueArr;
                }
                this.Page = 0;
                this.loadData();
            },
            searchClose(i){
                if(this.searchList.length ==1){
                    this.searchList = [];
                    this.searchArr = [];
                }else{

                    let field = this.searchList[i].field;
                    let num ="";
                    let self = this;
                    for (let i = 0; i < self.searchArr.length; i++) {
                        if (self.searchArr[i].field == field) {
                            num = i;
                        }
                    }
                    if(num){
                        this.searchArr.splice(num,1);
                    }
                    this.searchList.splice(i,1);
                }
                this.Page = 0;
                this.loadData();
            },
            onShowSizeChange(current, pageSize){
                this.Page = 0;
                this.Limit = pageSize;
                this.loadData();
            },
            ChangePage(val){
                this.Page = val;
                this.loadData();
            },
            _showMOdelTest(type, e, list, txt) {
                this.selectType = txt;
                let init = '';
                let arr = {};
                arr.name = list;
                arr.field = txt;
                arr.value = '';
                arr.type = type;
                if (this.searchArr.length == 0) {
                    this.searchArr.push(arr);
                } else {
                    let self = this;
                    let activity = 0;
                    for (let i = 0; i < self.searchArr.length; i++) {
                        if (self.searchArr[i].field == txt) {
                            activity = 1;
                        }
                    }
                    if (activity != 1) {
                        this.searchArr.push(arr);
                    }
                }
                let data = {
                    list: list,
                    type: type,
                    show: true,
                    init: init,
                    event: e
                };

                if (txt == 'IsAp') {
                    data.list = this.IsApArr
                } else if (txt == 'Allowed') {
                    data.list = this.AllowedArr;
                } else if (txt == 'Operators') {
                    data.list = this.OperatorsArr;
                } else if (txt == 'FreePlan') {
                    data.list = this.FreePlanArr;
                } else if (txt == 'UrgentLavel') {
                    data.list = this.UrgentLavelArr;
                }
                this.showMOdelTest = data;
            },
            _tableGetData(val) {
                console.log(val);
                let self = this;
                let num = "";
                for (let i = 0; i < self.searchArr.length; i++) {
                    if (self.searchArr[i].field == self.selectType) {
                        num =i;
                        self.searchArr[i].value = val;
                    }
                }

                let arr = {};
                arr.field = self.searchArr[num].field;
                arr.name = self.searchArr[num].name;
                arr.type = self.searchArr[num].type;
                arr.value = val;
                if(self.searchList.length ==0){
                    self.searchList.push(arr);
                }else{
                    let fieldArr = [];
                    for (let s=0;s<self.searchList.length;s++){
                         if(self.searchList[s].field == self.searchArr[num].field){
                             self.searchList[s].value = val;
                         }
                        fieldArr.push(self.searchList[s].field)
                    }
                    if(fieldArr.indexOf(self.searchArr[num].field) ==-1){
                        self.searchList.push(arr);
                    }
                }
                this.Page = 0;
                this.loadData();
            },
            _Allowed(value,Id){
                let Allowed = '';
                if(value==1){
                    Allowed = 0;
                }else{
                    Allowed = 1;
                }
                let self = this;
                this.$message.loading('操作中..',0);
                this.$axios.get(12920,{
                    Id:Id,
                    Allowed:Allowed,
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        self.loadData();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            } ,
            _del(Id){
                let self = this;
                this.$message.loading('操作中..',0);
                this.$axios.get(12919,{
                    Id:Id,
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        self.loadData();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _add(){
                this.Id='';
                this.visible = true;
            },
            _info(infoData){

                this.Id = infoData.Id;
                this.infoData = infoData;
                console.log(infoData);
                this.visible = true;
            },
            onClose(){
                this.visible = false;
                this.Id = '';
                this.infoData ={};
                this.loadData() ;
            },
            _record(infoData){
                this.Id = infoData.Id;
                this.infoData = infoData;
                this.recordVisible = true;
            },
            recordClose(){
                this.recordVisible = false;
                this.Id = '';
                this.infoData ={};
            },
        }
    }
</script>

<style scoped lang="less">
    .content{
        background: #ffffff;
        padding: 20px;
    }
    .table-action{
        color: #2994ff;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
    }
    .search {
        display: flex;
        padding: 0px 15px;
        margin-bottom: 20px;
        /*margin-bottom: 20px;*/
        .tag-group {
            flex: 1;
            display: flex;
            align-items: center;
            .tag-item {
                width: auto;
                border: 1px solid #dddddd;
                border-radius: 15px;
                padding: 0px 5px;
                margin-right: 15px;
                .tags {
                    border: 0;
                    margin: 0;
                }
                .tags-active {
                    color: #2994ff;
                }
            }
        }
    }
</style>