var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ddnsRecord"},[_c('a-textarea',{staticClass:"markdown",attrs:{"placeholder":"请输入记录"},model:{value:(_vm.Remark),callback:function ($$v) {_vm.Remark=$$v},expression:"Remark"}}),_c('a-button',{staticClass:"markdown",attrs:{"type":"primary"},on:{"click":function($event){return _vm._add()}}},[_vm._v("添加")]),_c('a-table',{attrs:{"data-source":_vm.tableList,"pagination":false,"columns":_vm.tableColumns}}),(_vm.total)?_c('a-pagination',{staticStyle:{"margin-top":"20px","text-align":"right"},attrs:{"pageSize":_vm.Limit,"total":_vm.total},on:{"change":_vm.ChangePage}}):_vm._e(),_c('div',{staticStyle:{"z-index":"100000"},style:({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  borderTop: '1px solid #e8e8e8',
  padding: '10px 16px',
  textAlign: 'left',
  left: 0,
  background: '#fff',
  borderRadius: '0 0 4px 4px'
})},[_c('a-button',{staticStyle:{"marginRight":"8px"},on:{"click":_vm.onClose}},[_vm._v(" 关闭 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }