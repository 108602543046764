<template>
    <div class="content" >
         <div class="group">
            <div class="label">标题</div>
             <a-input class="item" v-model="Title" placeholder="请输入标题" />
         </div>
        <div class="group">
            <div class="label">DDNS</div>
            <a-input class="item" v-model="Ddns"  placeholder="请输入DDNS" />
        </div>
        <div class="group">
            <div class="label">内网IP</div>
            <a-input class="item" v-model="Network" placeholder="请输入内网IP" />
        </div>
        <div class="group">
            <div class="label">掩码</div>
            <a-input class="item" v-model="Mask"  placeholder="请输入掩码" />
        </div>
        <div class="group">
            <div class="label">域控域名</div>
            <a-input class="item" v-model="Domain"  placeholder="请输入域控域名" />
        </div>
        <div class="group">
            <div class="label">办公网速</div>
            <a-input class="item" v-model="NetworkSpeed"  placeholder="请输入办公网速" />
        </div>
        <div class="group">
            <div class="label">公共网速</div>
            <a-input class="item" v-model="PublicSpeed"  placeholder="请输入公共网速" />
        </div>
        <div class="group">
            <div class="label">本月网费</div>
            <a-input-number class="item" v-model="Free" :min="0"  placeholder="请输入本月网费" />
        </div>
        <div class="group">
            <div class="label">原本网费</div>
            <a-input-number class="item" v-model="OldFree" :min="0"  placeholder="请输入原本网费" />
        </div>
        <div class="group">
            <div class="label">路由器用户名</div>
            <a-input class="item" v-model="RouterName"  placeholder="请输入路由器用户名" />
        </div>
        <div class="group">
            <div class="label">路由器密码</div>
            <a-input class="item" v-model="RouterPassword"  placeholder="请输入路由器密码" />
        </div>
        <div class="group">
            <div class="label">路由器型号</div>
            <a-input class="item" v-model="RouterModel"  placeholder="请输入路由器型号" />
        </div>
        <div class="group">
            <div class="label">是否实现ap和路由器自动重启</div>
            <a-select   v-model="IsAp"  class="item">
                <a-select-option value="1">
                    是
                </a-select-option>
                <a-select-option value="0">
                    否
                </a-select-option>

            </a-select>
        </div>
        <div class="group">
            <div class="label">降费计划</div>
            <a-select   v-model="FreePlan"  class="item">
                <a-select-option value="计划降费">
                    计划降费
                </a-select-option>
                <a-select-option value="保持不变">
                    保持不变
                </a-select-option>
                <a-select-option value="洽谈中">
                    洽谈中
                </a-select-option>
                <a-select-option value="完成降费">
                    完成降费
                </a-select-option>

            </a-select>
        </div>
        <div class="group">
            <div class="label">计划降费时间</div>
            <a-date-picker class="item" v-if="FreePlanTime" :default-value="moment(FreePlanTime, 'YYYY-MM-DD')" @change="changeTime($event,'FreePlanTime')" />
            <a-date-picker class="item" v-if="!FreePlanTime" @change="changeTime($event,'FreePlanTime')" />

        </div>
        <div class="group">
            <div class="label">完成降费时间</div>
            <a-date-picker class="item" v-if="FreeCompleteTime" :default-value="moment(FreeCompleteTime, 'YYYY-MM-DD')" @change="changeTime($event,'FreeCompleteTime')" />
            <a-date-picker class="item" v-if="!FreeCompleteTime" @change="changeTime($event,'FreeCompleteTime')" />
            
        </div>
        
        <div class="group">
            <div class="label">运营商</div>
            <a-select   v-model="Operators"  class="item">
                <a-select-option value="电信">
                    电信
                </a-select-option>
                <a-select-option value="移动">
                    移动
                </a-select-option>
                <a-select-option value="联通">
                    联通
                </a-select-option>
                <a-select-option value="鹏博士">
                    鹏博士
                </a-select-option>
            </a-select>
        </div>
        <div class="group">
            <div class="label">ddns密码</div>
            <a-input class="item" v-model="DdnsPassword"  placeholder="请输入ddns密码" />
        </div>
        <div class="group">
            <div class="label">nas用户名</div>
            <a-input class="item" v-model="NasName"  placeholder="请输入nas用户名" />
        </div>
        <div class="group">
            <div class="label">nas密码</div>
            <a-input class="item" v-model="NasPassword"  placeholder="请输入nas密码" />
        </div>
        <div class="group">
            <div class="label">中心名称</div>
            <person v-bind="schoolarr" style="width: 400px" class="item" @getData="ChangeUser"></person>
        </div>
        <div class="group">
            <div class="label">运维说明</div>
            <a-textarea v-model="Remark" class="item" placeholder="请输入运维说明"/>
        </div>
        <div
                :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
                style=" z-index: 100000"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                取消
            </a-button>
            <a-button type="primary" @click="_action()">
            确定
            </a-button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import person from '../../components/SelectPersonnel/SelectPersonnel';
    export default {
        name: "ddnsAction",
        components: {person},
        data(){
            return{
                Title:'',
                Ddns:'',
                Network:'',
                Mask:'',
                Domain:'',
                NetworkSpeed:'' ,
                PublicSpeed:'',
                Free:'',
                RouterName:'',
                RouterPassword:'',
                RouterModel:'',
                IsAp:'',
                FreePlan:'',
                Operators:'',
                DdnsPassword:'',
                NasName:'',
                NasPassword:'',
                SchoolId:'',
                SchoolName:'',
                schoolarr:{
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 4, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "recent11", //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                } ,
                Remark:'',
                OldFree:0,
                FreePlanTime:'',
                FreeCompleteTime:'',

            }
        },
        props: {
            Id: {
                type: String
            },
            infoData: {
                type: Object
            },
        },
        created(){
            if(this.Id){
                console.log(this.infoData);
                this._Info();
            }
        },
        methods:{
            moment,
            changeTime(value,name){
               
                let str1 = value._d;
                let date1 = new Date(str1);
                let startDate = date1.getFullYear() + '-' + (date1.getMonth() + 1 < 10 ? '0' + (date1.getMonth() + 1) : date1.getMonth() + 1) + '-' + (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate());
                if (name == 'FreeCompleteTime') {
                    this.FreeCompleteTime = startDate;
                }else if(name=='FreePlanTime'){
                    this.FreePlanTime = startDate;
                }
            },
            _action(){
                 let self = this;
                 if(!this.Title){
                    this.$message.error('请输入标题');
                    return false;
                 }
                 let data = {
                     Id:self.Id,
                     Title:self.Title,
                     Ddns:self.Ddns,
                     Network:self.Network,
                     Mask:self.Mask,
                     Domain:self.Domain,
                     NetworkSpeed:self.NetworkSpeed,
                     PublicSpeed:self.PublicSpeed,
                     Free:self.Free,
                     RouterName:self.RouterName,
                     RouterPassword:self.RouterPassword,
                     RouterModel:self.RouterModel,
                     IsAp:self.IsAp,
                     FreePlan:self.FreePlan,
                     Operators:self.Operators,
                     DdnsPassword:self.DdnsPassword,
                     NasName:self.NasName,
                     NasPassword:self.NasPassword,
                     SchoolName:self.SchoolName,
                     SchoolId:self.SchoolId,
                     Remark:self.Remark,
                     OldFree:self.OldFree,
                     FreePlanTime:self.FreePlanTime,
                     FreeCompleteTime:self.FreeCompleteTime,
                 }
                 this.$message.loading('操作中..',0);
                 this.$axios.post(12918,data,res=>{
                     this.$message.destroy();
                     if(res.data.code==1){
                         this.$message.success(res.data.msg);
                          self.onClose();
                     }else{
                         this.$message.error(res.data.msg);
                     }
                 })
            },
            _Info(){
                this.Title = this.infoData.Title;
                this.Ddns = this.infoData.Ddns;
                this.Network = this.infoData.Network;
                this.Mask = this.infoData.Mask;
                this.Domain = this.infoData.Domain;
                this.NetworkSpeed = this.infoData.NetworkSpeed;
                this.PublicSpeed = this.infoData.PublicSpeed;
                this.Free = this.infoData.Free;
                this.RouterName = this.infoData.RouterName;
                this.RouterPassword = this.infoData.RouterPassword;
                this.RouterModel = this.infoData.RouterModel;
                this.IsAp = this.infoData.IsAp+'';
                this.FreePlan = this.infoData.FreePlan;
                this.Operators = this.infoData.Operators;
                this.DdnsPassword = this.infoData.DdnsPassword;
                this.NasName = this.infoData.NasName;
                this.NasPassword = this.infoData.NasPassword;
                this.SchoolName = this.infoData.SchoolName;
                this.SchoolId = this.infoData.SchoolId;
                this.Remark = this.infoData.Remark;
                this.FreeCompleteTime = this.infoData.FreeCompleteTime;
                this.FreePlanTime = this.infoData.FreePlanTime;
                this.OldFree = this.infoData.OldFree;
                if(this.infoData.SchoolId){
                    let arr ={};
                    arr.id = this.infoData.SchoolId;
                    arr.title = this.infoData.SchoolName;
                    this.schoolarr.selectArr.push(arr);
                    console.log(this.schoolarr);
                }
            },
            _empty(){
                this.Title ="";
                this.Ddns ='';
                this.Ip ='';
                this.Mask = '';
                this.Domain ='';
                this.NetworkSpeed ='';
                this.PublicSpeed ='';
                this.Free ='';
                this.RouterName = '';
                this.RouterPassword ='';
                this.RouterModel ='';
                this.IsAp ='';
                this.FreePlan ='';
                this.Operators ='';
                this.DdnsPassword ='';
                this.NasName ='';
                this.NasPassword ='';
                this.SchoolName ='';
                this.SchoolId ='';
                this.schoolarr.selectArr =[];
                this.OldFree =0;
                this.FreePlanTime ='';
                this.FreeCompleteTime ='';
            },
            onClose(){
                this._empty()
                this.$emit('visible', false)
            },

            ChangeUser(val){
                if(val.length >0){
                    this.SchoolName = val[0].title;
                    this.SchoolId   = val[0].id;
                }else{
                    this.SchoolId   = '';
                    this.SchoolName ='';
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .group{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label{
            width: 80px;
        }
        .item{
            width: 400px;
        }
    }

</style>